<template>
  <!-- Centered Content -->
  <div class="page-content">
    <div class="h-100 d-flex align-items-center">
      <div class="container wide-container">
        <slot></slot>
      </div>
    </div>
  </div>
  <!-- ./Centered Content -->
</template>

<script>
export default {
  name: "WeCenter",
};
</script>
